/**
 * Singleton class for managing user sessions
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file SessionManager.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

import { addToLocalStorage, eraseFromLocalStorage, readFromLocalStorage, capitalizeFirstLetter, isBoolean, isObject, clearLocalStorage } from "./ApiUtilities"

/**
 * Handles logged in client sessions
 */
class SessionManager {
    constructor() {
        this.defaultUserSettings = {
            Measurement: "Imperial",
            Theme: "Auto",
            RatingsDefault: "AirQuality",
            DashboardDefault: "Info"
        }

        this.isBrowser = document
        this.siteId = undefined
        this.permit = undefined
        this.userSettings = undefined
        this.Authorized = false
        this.checkedTokens = false
        this.currentSiteId = undefined

        this.loginError = undefined

        this.eventListeners = {}
    }

    /**
     * Get login error
     * @returns Undefined if no login error, an error object if there was an error logging in
     */
    getLoginError() {
        return this.loginError
    }

    /**
     * Check if user is authorized
     * @returns True if user is authorized and the session is created
     */
    isAuthorized() {
        return this.Authorized
    }

    /**
     * Authorize session
     */
    authorizeSession() {
        this.Authorized = true
    }

    /**
     * Deauthorize session
     */
    deauthorizeSession() {
        this.Authorized = false
    }

    /**
     * Add function to set token listener
     * @param {function} func Function to add as listener 
     */
    addSetTokensListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['setTokensListener'] === undefined) {
            this.eventListeners['setTokensListener'] = []
        }

        this.eventListeners['setTokensListener'].push(func)
    }

    /**
     * Remove function from set token listener
     * @param {function} func Function to remove from listener 
     */
    removeSetTokensListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['setTokensListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['setTokensListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch set token listener
     */
    async dispatchSetTokensListeners() {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['setTokensListener'] !== undefined && this.eventListeners['setTokensListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['setTokensListener'].length; index++) {
                let listener = this.eventListeners['setTokensListener'][index]
                await listener()
            }
        }
    }

    /**
     * Add function to delete token listener
     * @param {function} func Function to add to listener 
     */
    addDeleteTokensListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['deleteTokensListener'] === undefined) {
            this.eventListeners['deleteTokensListener'] = []
        }

        this.eventListeners['deleteTokensListener'].push(func)
    }

    /**
     * Remove function to delete token listener
     * @param {function} func Function to remove from listener 
     */
    removeDeleteTokensListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['deleteTokensListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['deleteTokensListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch delete tokens
     */
    async dispatchDeleteTokensListeners() {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['deleteTokensListener'] !== undefined && this.eventListeners['deleteTokensListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['deleteTokensListener'].length; index++) {
                let listener = this.eventListeners['deleteTokensListener'][index]
                await listener()
            }
        }
    }

    /**
     * Add function to timeout session listener
     * @param {function} func Function to add to listener 
     */
    addTimeoutSessionListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['timeoutSessionListener'] === undefined) {
            this.eventListeners['timeoutSessionListener'] = []
        }

        this.eventListeners['timeoutSessionListener'].push(func)
    }

    /**
     * Remove function from timeout session listener
     * @param {function} func Function to remove from listener 
     */
    removeTimeoutSessionListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['timeoutSessionListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['timeoutSessionListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch time out session listeners
     */
    async dispatchTimeoutSessionListeners() {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['timeoutSessionListener'] !== undefined && this.eventListeners['timeoutSessionListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['timeoutSessionListener'].length; index++) {
                let listener = this.eventListeners['timeoutSessionListener'][index]
                await listener()
            }
        }
    }

    /**
     * Add function to create session listener
     * @param {function} func Function to add to listener 
     */
    addCreateSessionListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['createSessionListener'] === undefined) {
            this.eventListeners['createSessionListener'] = []
        }

        this.eventListeners['createSessionListener'].push(func)
    }

    /**
     * Remove function from create session listener
     * @param {function} func Function to remove from listener 
     */
    removeCreateSessionListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['createSessionListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['createSessionListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch create session listener
     */
    async dispatchCreateSessionListeners() {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['createSessionListener'] !== undefined && this.eventListeners['createSessionListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['createSessionListener'].length; index++) {
                let listener = this.eventListeners['createSessionListener'][index]
                await listener()
            }
        }
    }

    /**
     * Add function to destroy session listener
     * @param {function} func Function to add to listener 
     */
    addDestroySessionListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['destroySessionListener'] === undefined) {
            this.eventListeners['destroySessionListener'] = []
        }

        this.eventListeners['destroySessionListener'].push(func)
    }

    /**
     * Remove function from destroy session listener
     * @param {function} func Function to remove from listener 
     */
    removeDestroySessionListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['destroySessionListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['destroySessionListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch destroy session listener
     */
    async dispatchDestroySessionListeners() {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['destroySessionListener'] !== undefined && this.eventListeners['destroySessionListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['destroySessionListener'].length; index++) {
                let listener = this.eventListeners['destroySessionListener'][index]
                await listener()
            }
        }
    }

    /**
     * Logs out of session manager
     * @returns true if logged out successfully, returns false if not logged out
     */
    async destroySession () {
        /**
         * Pseudo Code
         *  Init access and refresh token
         *  If is browser
         *      Get access token and refresh token from local storage
         *      Clear local storage
         *  If sessionLogoutHandler is available in event listeners
         *      Run sessionLogoutHandler
         *  Else
         *      Console warn no session logout handler
         *  Log user out from auth route
         *  If fail
         *      return false
         *  Else
         *      Reinitialize to construction state
         *      Return true
         */

        let accessToken, refreshToken

        // If in browser mode
        if (this.isBrowser) {
            // Get access and refresh from local storage
            accessToken = readFromLocalStorage('accessToken')
            refreshToken = readFromLocalStorage('refreshToken')
        }

        // Deauthorize session
        this.deauthorizeSession()

        // Clear local storage
        clearLocalStorage()

        await this.dispatchDestroySessionListeners()
        
        // Log user out from auth route
        let res = await this.router.auth.logOutUser(accessToken, refreshToken)
        
        // If fail
        if (!res.isSuccess) {
            // Return false
            return false
        }
        else {
            // Initialze to construction state
            this.initializeSession()

            return true
        }
    }

    /**
     * Logs user into session manager
     * @returns {object} Status object, returns a true status if success, if fail returns false status and the reason for fail
     */
    async createSession() {
        /**
         * Pseudo Code
         *  Init access token and log in response
         *  If is browser
         *      Get from local storage
         *  If accessToken is not populated
         *      Populate response with an error
         *  Else
         *      Get user permissions
         *      If user failed to get user permission
         *          Populate response with error
         *      Else
         *          Attempt to get user settings
         *          If failed to get user settings
         *              Populate response with an error
         *  If response has an error status
         *      If is browser
         *          clear local storage
         *  If sessionLoginHandler has a listener
         *      Call sessionLoginHandler and pass through the login response 
         */

        // Init access token
        let accessToken
  
        // Init force two factor auth
        let forceTwoFactorAuthSetup

        // Init create session response 
        let response = {status: true}
        
        // Get access token
        if (this.isBrowser) {
            // From local storage
            accessToken = readFromLocalStorage('accessToken')

            // From local storage
            forceTwoFactorAuthSetup = readFromLocalStorage('forceTwoFactorAuthSetup')
        }

        // Check for access token
        if ( accessToken === '' || !accessToken || accessToken === "undefined" ) {
            response = {status: false, message: 'invalid_access_token', invalid: 'accessToken'}
        }
        else if (forceTwoFactorAuthSetup === true) {
            response = {status: false, message: 'force_two_factor_auth_setup', invalid: 'forceTwoFactorAuthSetup'}
        }
        else {
            // Get user permissions
            let userPermitted = await this.permitUser(accessToken) 
            
            // If failed to get user permissions
            if (userPermitted.status === false) {
                // Set error response
                response = userPermitted
            }
            else {
                // Save user setting
                let userSettingsSaved = await this.saveUserSettings()

                // If failed to save user settings
                if (userSettingsSaved.status === false) {
                    // Set error response
                    response = userSettingsSaved
                }
            }
        }

        if (response.status === true) {
            this.authorizeSession()
            this.loginError = undefined
        }
        else {
            this.loginError = response

            // If not force two factor auth
            if (response.invalid === 'forceTwoFactorAuthSetup') {
                this.forceTwoFactorAuthSetup = true

                this.authorizeSession()
            } 
            // If anything but timeout
            else if (response.invalid !== 'timeout') {
                if (this.isBrowser) {
                    clearLocalStorage()
                }

                this.deauthorizeSession()
            }
            else {
                this.deauthorizeSession()
            }
        }

        this.checkedTokens = true
        
        await this.dispatchCreateSessionListeners(response)

        return response
    }
    
    /**
     * Runs on time out of a request
     */
    async timeoutOfSessionManager() {
        /**
         * Pseudo Code
         *  If no session timeout handler specified warn that session timeout is not being handled 
         */

        await this.dispatchTimeoutSessionListeners()
    }

    /**
     * Set user tokens
     * @param {object} params Function parameters
     * @param {string} params.AccessToken Access token
     * @param {string} params.RefreshToken Refresh token
     * @param {string} params.IdToken Id token
     * @param {boolean} params.ForceTwoFactorAuthSetup Force two factor auth setup
     * @param {boolean} params.cancelCallback Cancel callback
     * @returns {object} Status object
     */
    async setTokens({AccessToken, RefreshToken, IdToken, ForceTwoFactorAuthSetup}, cancelCallback = false) {
        /**
         * Pseudo Code
         *  Define days to expire
         *  If in browser mode
         *      Save tokens to local storage
         *  Dispatch set tokens listener
         */

        // How many days items last in local storage
        const days = 7
        
        // If in browser mode
        if (this.isBrowser) {
            addToLocalStorage("accessToken", AccessToken, days)
            addToLocalStorage("refreshToken", RefreshToken, days)
            addToLocalStorage("userIdToken", IdToken, days)
            
            // If force two factor auth setup is true
            if (ForceTwoFactorAuthSetup === true) {
                addToLocalStorage("forceTwoFactorAuthSetup", ForceTwoFactorAuthSetup, days)
            }
        }

        if (!cancelCallback) {
            await this.dispatchSetTokensListeners()
        }
    }

    /**
     * @description Removes authorization tokens from local storage
     */
    async deleteTokens() {
        /**
         * Pseudo Code
         *  If in browser mode
         *      Delete tokens from local storage
         *  Dispatch delete tokens listener
         */

        if (this.isBrowser) {
            eraseFromLocalStorage("accessToken")
            eraseFromLocalStorage("refreshToken")
            eraseFromLocalStorage("userIdToken")
            eraseFromLocalStorage("forceTwoFactorAuthSetup")
        }

        await this.dispatchDeleteTokensListeners()
    }

    /**
     * Checks if tokens are available
     * @returns True if tokens are available. False if otherwise
     */
    hasTokens() {
        /**
         * Pseudo Code
         *  If is in browser mode
         *      Return if all tokens are available
         */
        
        if (this.isBrowser) {
            return (readFromLocalStorage('accessToken') !== null && readFromLocalStorage('refreshToken') !== null && readFromLocalStorage('userIdToken') !== null)
        }

        return false
    }

    /**
     * Check if tokens have been checked
     * @returns True if tokens were checked
     */
    hasCheckedTokens() {
        return this.checkedTokens
    }

    /**
     * Adds router to be used inside of session
     * @param {object} router Add router to session after construction of both classes
     */
    addRouter(router) {
        this.router = router
    }

    /**
     * Adds websocket to be used inside of session
     * @param {object} webSocket Add websocket to session after construction of both classes
     */
    addWebSocketManager(webSocketManager) {
        this.wsManager = webSocketManager
    }

    /**
     * Initialize session
     */
    initializeSession() {
        this.siteId = undefined
        this.permit = undefined
        this.userSettings = undefined
        this.Authorized = false
        this.loginError = undefined
        this.checkedTokens = false
    }

    /**
     * @description Get site id from url or memory
     * @param {string} defaultValue Default value for site Id. If you use this it is mostlikely set to "me" 
     * @returns {string} Site id from url, from default, or return "me"
     */
    getSiteId(defaultValue = null) {
        let site
        
        // If no site id in url
        if (site === undefined) {
            // If no defalt value specified
            if (defaultValue === null) {
                site = this.siteId
            }
            else if (defaultValue === "me") {
                site = defaultValue
            }
        }

        // If default
        if (defaultValue === "default") {
            site = this.siteId
        }
        
        return site
    }

    /**
     * Set user settings
     * @param {object} settings User settings returned from api call
     */
    setUserSettings({Theme, Measurement, RatingsDefault, DashboardDefault}) {
        this.userSettings = {
            Theme: Theme ?? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'Dark' : 'Light'),
            Measurement: Measurement ?? 'Metric',
            DashboardDefault: DashboardDefault ?? 'Info',
            RatingsDefault: RatingsDefault ?? 'Comfort'
        }
    }

    /**
     * Get current user settings
     * @returns {object} Current user settings
     */
    getUserSettings() {
        return this.userSettings
    }

    /**
     * Set site settings
     * @param {object} settings Site settings returned from api call
     */
    setSiteSettings({Theme, Measurement, RatingsDefault, DashboardDefault}) {
        this.siteSettings = {
            Theme: Theme ?? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'Dark' : 'Light'),
            Measurement: Measurement ?? 'Metric',
            DashboardDefault: DashboardDefault ?? 'Info',
            RatingsDefault: RatingsDefault ?? 'Comfort'
        }

        this.timezone = Intl?.DateTimeFormat()?.resolvedOptions().timeZone ?? 'UTC'
    }

    /**
     * Get current site settings
     * @returns {object} Current user settings
     */
    getSiteSettings() {
        return this.siteSettings
    }

    /**
     * @description Get site permissions
     * @returns {object} Site Permissions
     */
    getPermissions() {
        return (this.permit)
    }

    /**
     * Get permissions for user
     * @param {string} accessToken User access token
     * @returns {object} Status object, returns a true status if success, if fail returns false status and the reason for fail
     */
    async permitUser(accessToken) {
        /**
         * Pseudo Code
         *  Get user permissions
         *  If success getting user permissions
         *      Initialize allowPages array, urls, features, and uifeatures
         *      Map through recieved permissions, get url path
         *          If permission key starts with View
         *              Push to allow pages
         *              Populate Url object
         *              Populate UiFeatures object
         *          Else
         *              Get feature path
         *              If page in features has not yet been initialize
         *                  Initialize page in features
         *              If permission key starts with Add
         *                  Push Add to the feature set for feature path
         *                  Add permission key to UiFeatures
         *              If permission key starts with Delete
         *                  Push Delete to the feature set for feature path
         *                  Add permission key to UiFeatures
         *              If permission key starts with Edit
         *                  Push Edit to the feature set for feature path
         *                  Add permission key to UiFeatures
         *              Else
         *                  Handle custom actions
         *      Allow default pages and push to allow pages
         *      Create permit
         *      Return success status
         *   Else
         *      Return invalid permission status
         */
        
        // Get user permissions
        let res = await this.router.users.getPermissions(accessToken) 

        // If success getting permissions
        if (res.isSuccess) {
            // Init arrays
            let AllowPages = []
            let Urls = {}
            let Features = {}
            let UiFeatures = {}
            
            // Map Permissions
            res.data.Permissions.map((perm) => {
                let path = perm.Url.substring(1).split("/")  
                if (perm.Key.startsWith("View")) {
                    let allowPage = path.join('-')
                    AllowPages.push(allowPage)
                    Urls[perm.Url] = true
                    UiFeatures[perm.Key] = true
                }
                else {
                    let featurePath = perm.Url

                    // Initialize Page Feature if it hasn't been initalized
                    if (Features[featurePath] === undefined) {
                        Features[featurePath] = []
                    }

                    // Add Feature access if included in feature set
                    if (perm.Key.startsWith("Add")) {
                        Features[featurePath].push({name: 'add', action: perm.Key, displayName: perm.ActionName})
                        UiFeatures[perm.Key] = true
                    }
                    else if (perm.Key.startsWith("Delete")) {
                        Features[featurePath].push({name: 'delete', action: perm.Key, displayName: perm.ActionName})
                        UiFeatures[perm.Key] = true
                    }
                    else if (perm.Key.startsWith("Edit")) {
                        Features[featurePath].push({name: 'edit', action: perm.Key, displayName: perm.ActionName})
                        UiFeatures[perm.Key] = true
                    }
                    else {
                        /** Handle Custom Actions Here **/
                        Features[featurePath].push({name: 'custom', action: perm.Key, displayName: perm.ActionName})
                        UiFeatures[perm.Key] = true
                    }
                }
            })
        
            // Allow these pages by default
            let defaultPages = [ "dashboard", "my-schedule", "account-info", "announcements", "change-password", "multi-factor-auth" ]
            defaultPages.forEach((page) => AllowPages.push(page))

            let permit = { AllowPages: AllowPages, Features: Features, Urls: Urls, UiFeatures}
            this.permit = permit
            this.priorityLevel = res.data.Priority
            
            return {status: true}
        }
        else {
            if (res.status === 504) {
                return {status: false, reason: 'timeout', invalid: 'timeout'}
            }

            return {status: false, reason: 'invalid_permissions', invalid: 'permissions'}
        }
    }

    /**
     * Get user setings for a user
     * @returns {object} Status object, returns a true status if success, if fail returns false status and the reason for fail
     */
    async saveUserSettings() {
        /**
         * Pseudo Code
         *  Init settings object
         *  Get siteId from site info
         *  If fails
         *      Return failed status
         *  Else
         *      Set site id on get user settings
         *      Get site default settings
         *  Get user settings
         *  If success
         *      Set user settings
         *      Return success status
         *  Else
         *      Return failed status
         *  
         */
        let settings = {}
        
        // Get SiteId
        let res = await this.router.sites.getSiteInfo()
        
        if (!res.isSuccess) {
            return {status: false, reason: 'failed_to_get_siteId', invalid: 'siteId'}
        }
        else {
            // Set site id on get user settings
            this.setSiteId(res.data.Id)
            
            // Get site default settings
            settings = res.data.Settings

            this.setSiteSettings({...this.defaultUserSettings, ...settings})
        }
        
        // Get user settings 
        res = await this.router.users.getMe()

        if (res.isSuccess) {
            // Site default settings or user settings
            settings = { ...this.defaultUserSettings, ...settings, ...res.data.Settings}
           
            // Set user settings
            this.setUserSettings(settings)
            return {status: true, settings}
        }
        else {
            if (res.status === 504) {
                return {status: false, reason: 'timeout', invalid: 'timeout'}
            }

            return {status: false, reason: "could_not_find_user", invalid: 'user'}
        }
    }

    /**
     * @description Set site id
     * @param {string} siteId Session user's siteId
     */
    setSiteId(siteId) {
        this.siteId = siteId
    }

    /**
     * @description Set current site id
     * @param {string} siteId user's current siteId
     */
    setCurrentSiteId(newSiteId) {
        /**
         * Pseudo Code
         *  If new site id is undefined
         *      get current site id
         *  Else
         *      set prev site id to current siteid
         *      If prev site is not undefined AND new site id is different from prev site
         *          dispatch site id listeners
         *      set current site id to new site id
         */
        if (newSiteId === undefined) {
            this.currentSiteId = this.getSiteId()
        }
        else {
            let prevSiteId = this.currentSiteId

            if (prevSiteId !== undefined && newSiteId !== prevSiteId) {
                // trigger site id change
                this.dispatchSiteIdListeners(prevSiteId, newSiteId)
            }

            this.currentSiteId = newSiteId
        }
    }

    /**
     * Get current site id
     * @returns {string} curent siteId 
     */
    getCurrentSiteId() {
        return this.currentSiteId
    }

    /**
     * @description Takes in params and returns a REST API friendly search query
     * @param {Object} params Function parameters
     * @param {string} params.page Current dataview page number
     * @param {Object} params.searchFilters Current search filters
     * @param {string} params.siteId Current site Id
     * @param {string} params.deviceId Device id
     * @param {String} params.groupId Group Id
     * @param {string} params.mode Get the extremum (max or min) of
     * @param {boolean} params.countOnly Should get the total count only
     * @param {boolean} first First time data flag
     * @returns {string} REST API friendly Search Query
     */
    getQuery({page = 0, searchFilters = null, siteId = null, deviceId = null, groupId = null, countOnly = false, mode = null} = {}) {
        let query = []
        
        // Add page number if available
        if (page !== null) {
            query.push(`Page=${page}`)
        }
        if (deviceId !== null) {
            query.push(`DeviceId=${deviceId}`)
        }

        if (groupId !== null) {
            query.push(`GroupId=${groupId}`)
        }

        // Add siteId to query if available
        if (siteId !== null) {
            query.push(`SiteId=${siteId}`)
        }

        if (countOnly === true) {
            query.push(`CountOnly=${countOnly}`)
        }

        // Add siteId to query if available
        if (mode !== null) {
            query.push(`Mode=${mode}`)
        }

        if (searchFilters) {
            let filters = {...searchFilters}
            let keys = Object.keys(filters)

            for (let i = 0; i < keys.length; i++) {
                if (!Array.isArray(filters[keys[i]])) {
                    // Add value to query
                    query.push(`${capitalizeFirstLetter(keys[i])}=${filters[keys[i]]}`)
                }
                else {
                    // Add array to query
                    for (let j = 0; j < filters[keys[i]].length; j++) {
                        query.push(`${capitalizeFirstLetter(keys[i])}=${filters[keys[i]][j]}`)
                    }
                }
            }
        }
        
        return (query.join('&'))
    }

    /**
     * @description Convert filter object from url to api friendly object
     * @returns {object} search params in object format
     */
    convertFilterObject(searchParams) {
        let searchFilters = {...searchParams.searchFilters}
        if (searchFilters) {
            let keys = Object.keys(searchFilters)
            keys.forEach((key) => {
                if (isObject(searchFilters[key])) {
                    let obj = searchFilters[key]
                    let innerKeys = Object.keys(obj)
                    innerKeys.forEach((innerKey) => innerKey = innerKey.charAt(0).toUpperCase() + innerKey.slice(1))
                    searchFilters[key] = capitalizeFirstLetter(innerKeys)
                } 
                else if (Array.isArray(searchFilters[key])) {
                    for (let i = 0; i < searchFilters[key].length; i++) {
                        if (isObject(searchFilters[key][i]) === true) {
                            searchFilters[key][i] = this.convertFilterObject(searchFilters[key][i])
                        }
                        else if (!isBoolean(searchFilters[key][i])) {
                            if (typeof searchFilters[key][i] === 'string' || searchFilters[key][i] instanceof String) {
                                if (!key.includes('Id') && !key.includes('search')) {
                                    searchFilters[key][i] = capitalizeFirstLetter(searchFilters[key][i])
                                }
                            }
                        }                     
                    }
                }
                else {
                    if (!isBoolean(searchFilters[key])) {
                        if (searchFilters[key] === 'true' || searchFilters[key] === 'false') {
                            searchFilters[key] = searchFilters[key] === 'true'
                        }
                        else if (key === "sortBy" && searchFilters[key] === "status") {
                            searchFilters[key] = capitalizeFirstLetter("isActive")
                        }
                        else {
                            if (!key.includes('Id') && !key.includes('search')) {
                                searchFilters[key] = capitalizeFirstLetter(searchFilters[key])
                            }
                        }
                    }   
                }
            })
        }
        
        return({...searchParams, searchFilters: searchFilters})
    }

    /**
     * @description Gets refresh token from local storage
     * @returns {string} Refresh token as a string
     */
    getRefreshToken() {
        let refreshToken
        if (this.isBrowser) {
            refreshToken = readFromLocalStorage("refreshToken")
        }
        return (refreshToken)
    }

    /**
     * @description Gets access token from local storage
     * @returns {string} Access token as a string
     */
    getAccessToken() {
        let accessToken
        if (this.isBrowser) {
            accessToken = readFromLocalStorage("accessToken")
        }
        return (accessToken)
    }

    /**
     * @description Gets user id token from local storage
     * @returns {string} Id token as a string
     */
    getUserIdToken() {
        let userIdToken
        if (this.isBrowser) {
            userIdToken = readFromLocalStorage("userIdToken")
        }
        return (userIdToken)
    }

    /**
     * @description Get default view for dashboard
     * @returns Dashboard default from local storage or default to "Recent Alerts"
     */
    getDashboardDefault() {
        return readFromLocalStorage("dashboardDefault") ?? "Recent Alerts"
    }

    /**
     * @description Get default view for ratings
     * @returns {string} Ratings default from local storage or default to "Air Quality"
     */
    getRatingsDefault() {
        return readFromLocalStorage("ratingsDefault") ?? "Air Quality"
    }

    /**
     * Check if page is allowed by the current user's permissions
     * @param {string} page Page route
     * @return {boolean} true if page is allowed false if otherwise
     */
    pageAllowed(page) {
        return this.permit.AllowPages.includes(page)
    }

    /**
     * Check if page url is allowed to be viewed
     * @param {string} url Page url
     * @returns True if url can be viewed
     */
    urlAllowed(url) {
        return this.permit.Urls[url] === true
    }

    /**
     * Gets all features for specified page
     * @param {string} page Page name 
     * @returns Returns features for specified page
     */
    getFeaturesForPage(page) {
        return this.permit.Features[page]
    }

    /**
     * Check if user has access to a ui feature
     * @param {string} featureKey Ui feature key
     * @returns True if user has the specified ui feature key
     */
    hasUiFeature(featureKey) {
        return this.permit.UiFeatures[featureKey]
    }

    /**
     * Check if user has access to ui features
     * @param {string[]} featureKeys Ui feature keys
     * @returns True if user has all specified ui feature keys
     */
    hasUiFeatures(featureKeys) {
        if (!Array.isArray(featureKeys)) {
            return false
        }

        return featureKeys.every(featureKey => this.hasUiFeature(featureKey))
    }

    /**
     * Add function to create session listener
     * @param {function} func Function to add to listener 
     */
    addSiteIdListener(func) {
        /**
         * Pseudo Code
         *  If event listener is undefined 
         *      Define event listene
         *  Add function to event listener
         */

        if (this.eventListeners['siteIdListener'] === undefined) {
            this.eventListeners['siteIdListener'] = []
        }

        this.eventListeners['siteIdListener'].push(func)
    }

    /**
     * Remove function from create session listener
     * @param {function} func Function to remove from listener 
     */
    removeSiteIdListener(func) {
        /**
         * Pseudo Code
         *  Get index of function
         *  If index is not -1
         *      Remove function from event listener
         */

        let indexToRemove = this.eventListeners['siteIdListener'].indexOf(func)
        
        if (indexToRemove !== -1) {
            this.eventListeners['siteIdListener'].splice(indexToRemove, indexToRemove + 1)
        }
    }

    /**
     * Dispatch create session listener
     */
    async dispatchSiteIdListeners(...args) {
        /**
         * Pseudo Code
         *  If event listener is not undefined and has at least one function
         *      For each function in event listener
         *          Get and run listener
         */

        if (this.eventListeners['siteIdListener'] !== undefined && this.eventListeners['siteIdListener'].length > 0) {
            for (let index = 0; index < this.eventListeners['siteIdListener'].length; index++) {
                let listener = this.eventListeners['siteIdListener'][index]
                await listener(...args)
            }
        }
    }
}

export default SessionManager
