/**
 * Class for handling site REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file sites.js
 * @author Matt Schreider, Kyle Watkins, Paul Scala
*/

class Sites {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     *  @description Gets users site path by site id
     *  @param {string} siteId SiteId to get path for
     *  @param {boolean} me If me is true get current users path from user default site id
     *  @returns {ResponseObject} Api response object
     */
    async getPath(siteId = null, me = false) {
        let config
        if (!me) {
            config = {
                method: 'get',
                url: `/api/sites/${siteId}/path`,
            }
        }
        else {
            config = {
                method: 'get',
                url: `/api/sites/me/path`,
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
        
    }

    /**
     * @description Get site info by site id
     * @param {string} siteId Site id 
     * @returns {ResponseObject} Api response object
     */
    async getSiteInfo(siteId = null) {
        if (siteId === null) {
            siteId = this.session.getSiteId("me")
        }
        
        let config = {
            method: 'get',
            url: `/api/sites/${siteId}`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)
        
        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * @description Get site alert types by id and options
     * @param {string} siteId Site id to get alert types for
     * @param {string} options Options to get alert types for ('', or 'categorized')
     * @returns {ResponseObject} Api response object
     */
    async getAlertTypes(siteId = null, options = '') {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        options = '/' + options

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/alerttypes${options}`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)
        
        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get models available in site
     * @param {string} siteId Site id to get models for
     * @returns {ResponseObject} Api response object
     */
    async getModels(siteId = null) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/models`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get site tree of site
     * @param {string} siteId Site id to get site tree for 
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {boolean} searchfilters.isActive Filter by is active
     * @param {boolean} searchFilters.includeCurrentSite If true include current site in return
     * @returns {ResponseObject} Api response object
     */
    async getSiteTree(siteId = null, {page = 0, order = "ASC", search = "", sortBy = "", includeCurrentSite = false} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            ...(includeCurrentSite !== false && {includeCurrentSite: true})
        }
        
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let query = '?' + this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/sites/tree${query}`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get streaming types available in site
     * @param {string} siteId Site id to get streaming types for
     * @param {object} query api query object
     * @param {number} query.priorityLimit priority limit value
     * @returns {ResponseObject} Api response object
     */
    async getStreamingTypes(siteId = null, {priorityLimit = null}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/streamingtypes?${(priorityLimit !== null ? `priorityLimit=${priorityLimit}` : '')}`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get ratings types available in site
     * @param {string} siteId Site id to get ratings types for
     * @returns {ResponseObject} Api response object
     */
    async getRatingsTypes(siteId = null) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/ratingstypes`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get reports available in sites
     * @param {string} siteId Site id to get reports for
     * @param {object} query Get reports query
     * @param {string[]} query.reportTypes
     * @returns {ResponseObject} Api response object
     */
    async getReports(siteId = null, {reportTypes = ['All']} = {}) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let query = this.session.getQuery({
            searchFilters: {
                reportTypes: reportTypes
            }
        })

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/reports${"?" + query}`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * Get userroles available in sites
     * @param {string} siteId Site id to get user roles for
     * @returns {ResponseObject} Api response object
     */
    async getUserRoles(siteId = null) {
        if (siteId === null) {
            siteId = this.session.getSiteId()
        }

        let config = {
            method: 'get',
            url: `/api/sites/${siteId}/userroles`,
            headers: { 
                'Authorization': true
            }
        }

        let res = await this.axios(config)

        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }

    /**
     * @description Set site info
     * @param {Object} params Function parameters
     * @param {string} params.id Subsite id
     * @param {string} params.name Subsite name
     * @param {string} params.address Subsite street address
     * @param {string} params.city Subsite city
     * @param {string} params.country Subsite country
     * @param {string} params.state Subsite state
     * @param {string} params.timezone Subsite timezone
     * @param {string} params.zip Subsite zipcode
     * @param {object} params.settings Site settings
     * @param {string} params.settins.measurement Site measurment setting   
     * @param {string} params.tenantId Site tenantid
     * @param {[]object} params.forceRoleTwoFactorAuth Force two factor auth for specific role
     * @returns {ResponseObject} Api response object
     */
    async setSiteInfo({id = null, name = null, address = null, city = null, state = null, country = null, timezone = null, type = null, zip = null, tenantId = null, settings = {}, forceRoleTwoFactorAuth = []}) {
        let data = JSON.stringify({
            "Name": name,
            "Address": address,
            "State": state,
            "Zip": zip,
            "City": city,
            "Country": country,
            "TimeZone": timezone,
            "TenantId": tenantId,
            "OrganizationType": type,
            ...(Object.keys(settings).length !== 0 && {"Settings": {
                ...(settings.measurement && {"Measurement": settings.measurement})
            }}),    
            ...(forceRoleTwoFactorAuth.length !== 0 && {
                "ForceRoleTwoFactorAuth": forceRoleTwoFactorAuth
            })
        })

        let config = {
            method: 'put',
            url: `/api/sites/${id}`,
            headers: { 
                'Authorization': true, 
                'Content-Type': 'application/json'
            },
            data: data
        }
        let res = await this.axios(config)
        
        return (
            { 
                status: res.status,
                statusText: res.statusText,
                isSuccess: (res.status >= 200 && res.status < 300),
                data: res.data
            }
        )
    }
}

export default Sites
