/**
 * RouteManager Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file RouteManager.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React, { Suspense, useLayoutEffect, useState } from 'react'
import { withRouter, Redirect, Switch } from 'react-router-dom'
import Portal from '../portal'
import Base from '../base'
import Loading from '../pages/loading'
import Login from "../pages/login"
import AuthError from "../pages/authError"
import ApiManager from '../api/ApiManager'
import { getParents } from '../utilities/utilityFunctions'
import NoAuthError from '../pages/noAuthError'

/**
 * Lazy load page by string
 * @param {string} lazyPath Page string  
 * @returns {React.LazyExoticComponent<React.ComponentType<any>>} Lazy Loading Component
 */
const lazyLoadPage = (lazyPath = 'dashboard') => {
    if (lazyPath === '*') {
        return null
    }
    return React.lazy(() => (import(/* webpackChunkName: "common/pages/[request]" */ (`../pages/${lazyPath}.js`)) ))
}

/**
 * Suspended Page Component
 * @param {import('react').ReactNode} Element React Node Element 
 */
const SuspensePage = (Element) => ({fallback, ...props}) => {
    return (
        <Suspense fallback={fallback ?? null}>
            <Element {...props} />
        </Suspense>
    )
}

/**
 * @description All Site Pages
 * @usage Add additional pages here
 */
const pages = [
    // Public Paths

    // Protected Paths
    {
        route: '/login',
        name: 'login',
        component: Login,
        type: 'protected',
        container: 'base'
    },
    {
        route: '/update-password',
        name: 'update-password',
        component: SuspensePage(lazyLoadPage('updatePassword')),
        type: 'protected',
        container: 'base'
    },
    {
        route: '/mfa',
        name: 'mfa',
        component: SuspensePage(lazyLoadPage('mfa')),
        type: 'protected',
        container: 'base'
    },
    {
        route: '/mfa-setup',
        name: 'mfa-setup',
        component: SuspensePage(lazyLoadPage('mfa-setup')),
        type: 'private',
        container: 'base'
    },
    {
        route: '/register',
        name: 'register',
        component: SuspensePage(lazyLoadPage('register')),
        type: 'protected',
        container: 'base',
        devOnly: true
    },
    {
        route: '/register-site',
        name: 'register-site',
        component: SuspensePage(lazyLoadPage('registerSite')),
        type: 'protected',
        container: 'base',
    },
    {
        route: '/password',
        name: 'password',
        component: SuspensePage(lazyLoadPage('password')),
        type: 'protected',
        container: 'base'
    },
    {
        route: '/reset',
        name: 'reset',
        component: SuspensePage(lazyLoadPage('reset')),
        type: 'protected',
        container: 'base'
    },
    {
        route: '/confirm',
        name: 'confirm',
        component: SuspensePage(lazyLoadPage('confirm')),
        type: 'protected',
        container: 'base'
    },
    {
        route: '/about',
        name: 'about',
        component: SuspensePage(lazyLoadPage('about')),
        type: 'public',
        container: 'base'
    },
    {
        route: '/azure',
        name: 'azure',
        component: SuspensePage(lazyLoadPage('azure')),
        type: 'public',
        container: 'base'
    },
    {
        route: '/timeout',
        name: 'timeout',
        component: NoAuthError,
        onMain: false,
        onDropdown: false,
        checkAuthorization: true,
        type: 'protected',
        container: 'base',
        canRedirect: false
    },
    {
        route: '*',
        name: 'pagenotfound',
        component: NoAuthError,
        onMain: false,
        onDropdown: false,
        checkAuthorization: true,
        type: 'protected',
        container: 'base',
        canRedirect: false
    },

    // Private Paths
    {
        route: "/dashboard",
        name: "dashboard", 
        icon: "fas fa-tachometer-alt",
        component: lazyLoadPage('dashboard'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/alerts",
        name: "alerts", 
        icon: "fas fa-bell",
        component: lazyLoadPage('alerts'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/ratings",
        name: "ratings", 
        icon: "fas fa-star", 
        component: lazyLoadPage('ratings'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/devices",
        name: "devices", 
        icon: "icon-monitor4", 
        component: lazyLoadPage('devices'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/devices/live-sensor-data",
        name: "devices-live-sensor-data",
        displayName: "live-sensor-data",
        icon: "icon-monitor4",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('liveSensorData'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/devices/historical-sensor-data",
        name: "devices-historical-sensor-data",
        displayName: "historical-sensor-data",
        icon: "icon-monitor4",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('historicalSensorData'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/devices/alert-activity",
        name: "devices-alert-activity",
        displayName: "alert-activity",
        icon: "icon-monitor4",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('deviceAlertActivity'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/users",
        name: "users", 
        icon: "fas fa-users", 
        component: lazyLoadPage('users'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/subscriptions",
        name: "subscriptions", 
        icon: "fas fa-envelope-open-text", 
        component: lazyLoadPage('subscriptions'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/forecast",
        name: "forecast", 
        icon: "fa-solid fa-magnifying-glass-chart",
        component: lazyLoadPage('forecast'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/reports",
        name: "reports", 
        icon: "fa-sharp fa-solid fa-chart-column",
        component: lazyLoadPage('reports'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/reports/alerts",
        name: "reports-alerts",
        displayName: "alerts",
        icon: "fa-sharp fa-solid fa-chart-column",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('reportsAlertData'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/reports/alert-activity",
        name: "reports-alert-activity",
        displayName: "alert-activity",
        icon: "fa-sharp fa-solid fa-chart-column",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('alertActivityReport'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/reports/monthly-report",
        name: "reports-monthly-report",
        displayName: "monthly-report",
        icon: "fa-sharp fa-solid fa-chart-column",
        parents: ["reports"],
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('monthlyReport'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/reports/weekly-report",
        name: "reports-weekly-report",
        displayName: "weekly-report",
        icon: "fa-sharp fa-solid fa-chart-column",
        parents: ["reports"],
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('weeklyReport'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/site",
        name: "site", 
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('site'),
        type: 'private',
        container: 'portal'
    },
    {
        route: "/site/sub-sites", 
        name: "site-sub-sites",
        displayName: "sub-sites",  
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('subsites'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    }, 
    {   
        route: "/site/device-groups", 
        name: "site-device-groups",
        displayName: "device-groups", 
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('devicegroups'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },    
    {
        route: '/site/webhooks',
        name: 'site-webhooks',
        displayName: 'webhooks',
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('webhooks'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },  
    {
        route: '/site/webhooks/request',
        name: 'site-webhooks-request',
        displayName: 'request',
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('webhooksRequest'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },  
    {
        route: '/site/webhooks/events',
        name: 'site-webhooks-events',
        displayName: 'events',
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('webhookEvents'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },  
    {   
        route: "/site/user-lists",
        name: "site-user-lists",
        displayName: "user-lists", 
        icon: "fa-sharp fa-solid fa-building", 
        component: lazyLoadPage('userlists'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin",
        name: "admin",
        icon: "fas fa-lock",
        component: lazyLoadPage('admin'), 
        onMain: false, 
        onDropdown: true,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/users",
        name: "admin-users",
        displayName: "users",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('manageusers'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/system-messages",
        name: "admin-system-messages",
        displayName: "system-messages",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('managesystemmessages'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/devices",
        name: "admin-devices",
        displayName: "devices",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('managedevices'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/sites",
        name: "admin-sites",
        displayName: "sites",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('managesites'), 
        onMain: false, 
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/notifications",
        name: "admin-notifications",
        displayName: "notifications",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('notifications'), 
        onMain: false, 
        type: 'private',
        container: 'portal'
    },
    {
        route: "/admin/blacklist",
        name: "admin-blacklist",
        displayName: "blacklist",
        icon: "fas fa-lock",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('blacklist'), 
        onMain: false,
        type: 'private', 
        container: 'portal'
    },
    {
        route: '/admin/webhooks',
        name: 'admin-webhooks',
        displayName: 'Webhook Log',
        icon: "fa-sharp fa-solid fa-webhook", 
        component: lazyLoadPage('webhookLog'),
        onMain: false,
        type: 'private', 
        container: 'portal'
    },  
    {
        route: "/admin/alerts",
        name: "admin-alerts",
        displayName: "Alerts",
        icon: "fas fa-bell",
        dropdownIcon: "lock", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('adminAlerts'),
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/my-schedule",
        name: "my-schedule", 
        altName: "My Schedule", 
        icon: "fas fa-calendar",
        component: lazyLoadPage('myschedule'), // Dropdown can't use fas-fa icons
        onMain: false, 
        onDropdown: true,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/account-info",
        name: "account-info", 
        altName: "Account Information", 
        icon: "fas fa-user",
        component: lazyLoadPage('accountInfo'), // Dropdown can't use fas-fa icons
        onMain: false, 
        onDropdown: true,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/multi-factor-auth",
        name: "multi-factor-auth", 
        altName: "Multi-Factor Authentication", 
        icon: "fas fa-user",
        component: lazyLoadPage('multiFactorAuthSetup'), // Dropdown can't use fas-fa icons
        onMain: false, 
        onDropdown: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: "/change-password",
        name: "change-password", 
        icon: "fas fa-key", // Dropdown can't use fas-fa icons
        component: lazyLoadPage('changePassword'), 
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: '/help',
        name: 'help',
        icon: 'fas fa-question-circle',
        component: lazyLoadPage('help'),
        type: 'private',
        container: 'portal',
        onMain: false,
        onDropdown: true,
    },
    {   
        route: "/test", 
        name: "test",
        displayName: "test", 
        icon: "fas fa-flask", 
        devOnly: true,
        onMain: false,
        component: lazyLoadPage('test'), 
        type: 'private',
        container: 'portal',
    },
    {
        name: "logout", 
        icon: "logout", // Dropdown can't use fas-fa icons
        onMain: false,
        type: 'private',
        container: 'portal'
    },
    {
        route: '/sitenotfound',
        name: 'sitenotfound',
        component: AuthError,
        onMain: false,
        onDropdown: false,
        onAlways: true,
        type: 'private',
        container: 'portal'
    },
    {
        route: '/timeout',
        name: 'timeout',
        component: AuthError,
        onMain: false,
        onDropdown: false,
        checkAuthorization: true,
        onAlways: true,
        type: 'private',
        container: 'portal',
        canRedirect: false
    },
    {
        route: '*',
        name: 'pagenotfound',
        component: AuthError,
        onMain: false,
        onDropdown: false,
        checkAuthorization: true,
        onAlways: true,
        type: 'private',
        container: 'portal',
        canRedirect: false
    },
    {
        route: '/loading',
        name: 'loading',
        component: Loading,
        onMain: false,
        onDropdown: false,
        type: 'private',
        container: 'base'
    },
]

/**
 * Build container pages
 * @param {object} options Options to build container pages
 * @param {string[]} options.container Container to get for, (Ex. portal or base).
 * @param {string[]} options.types Security level type, (Ex. private, protected or public)
 * @param {boolean} options.canRedirect If false gets all routes. If true then only gets routes that can redirect. Defaults to false
 * @returns Array of pages for a container
 */
function buildContainerPages({containers = [], types = [], canRedirect = false}) {
    /**
     * Pseudo Code
     *  If containers or types are empty
     *      return empty array  
     *  Return filtered pages matching criterias of containers, types, and canRedirect
     */

    // If containers or types are empty
    if (containers.length === 0 || types.length === 0) {
        return []
    }

    return pages.filter(page => {
        // If page container is included in containers
        if (containers.includes(page?.container)) {
            // If page type is included in types
            if (types.includes(page?.type)) {
                // If can redirect is false or can redirect mode is true and page canRedirect is false
                if (canRedirect === false || (canRedirect === true && page.canRedirect !== false)) {
                    // If devOnly is not defined or page.devOnly is true and devmode is trye
                    if (page.devOnly === undefined || ((page?.devOnly) === true && process.env.DevMode)) {
                        return true
                    }
                }
            }
        }
        
        return false
    })
}

/**
 * @description Get permitted navbar navigation
 * @returns {object[]|boolean} Permited Navbar links
 */
function getNavBarLinks() {
    /**
     * Pseudo Code
     *  Filter pages that belong in nav bar
     */

    let links = pages.filter(link => {
        if (link.onMain !== false && (link.onAlways === true || (ApiManager.session?.permit?.AllowPages ?? []).includes(link.name))) {
            return true
        }
        return false
    })

    return links
}

/**
 * @description Get permitted dropdown navigation
 * @returns {object[]|boolean}  Permited Dropdown links
 */
function getDropdownLinks() {
    /**
     * Pseudo Code
     *  Filter pages that belong in settings dropdown
     */

    let links = pages.filter(link => {
        if (link.onDropdown === true && (ApiManager.session?.permit?.AllowPages ?? []).includes(link.name)) {
            return true
        }
        return false
    })
    
    return links
}

/**
 * @description Get permitted routes
 * @returns {object[]|boolean} Permited routes
 */
function getPermitedPrivatePages(containerPages = null) {
    /**
     * Pseudo Code
     *  If container pages is null
     *      Container pages equals pages
     *  Filter container pages if they are permitted pages
     */

    // If container pages is null
    if (containerPages === null) {
        containerPages = pages
    } 
    
    // Filter pages
    let links = containerPages.filter(link => {
        // If is private link
        if (link.type === 'private') {
            // Check if the user is permitteed
            if ((ApiManager.session?.permit?.AllowPages ?? []).includes(link.name) || link.onAlways === true) {
                // Get route parents
                let parents = getParents(link.route)
                
                // If route has parents
                if (parents.length > 1) {
                    // Check if all parents are allowed
                    let parentsAreAllowed = parents.every((p, index, arr) => {
                        let pageToAllow = arr.slice(undefined, index + 1).join('-') 
                         
                        return (ApiManager.session.permit.AllowPages ?? []).includes(pageToAllow) || (ApiManager.session?.permit?.AllowPages ?? []).includes(p) || link.onAlways === true
                    })

                    // Check if every parent is an allowed page
                    if (parentsAreAllowed) {
                        return true
                    }
                }
                else {
                    return true
                }
            }
            // Return true if dev only is true and dev mode is on
            else if ((link?.devOnly ?? false) === true && (process.env.DevMode)) {
                return true
            }
        }
        return false
    })

    return links
}

/**
 * Base route component
 * @param {object} props Component properties
 * @param {string} props.path Base route path 
 * @returns Base Route Redirect Location
 */
function BaseRoute({path}) {
    return (
        (ApiManager.session.hasTokens()) ? 
            <Redirect to='/loading' from={path} />
            :
            <Redirect to='/login' from={path} />
    )
}

/**
 * Get routes from pages if available
 * @param {object[]} containerPages Array of page objects 
 * @returns Array of routes as strings
 */
function getRoutesFromPages(containerPages) {
    return containerPages.filter(page => page.route !== undefined).map(page => {
        return page.route
    })
}

/**
 * Base Container Component
 * @param {object} props Component props
 * @param {object[]} props.pages Array of page objects
 * @returns Base Container
 */
function BaseContainer(props) {
    return (
        <Base pages={props.pages}></Base>
    )
}

/**
 * Portal Container Component
 * @param {object} props Component props
 * @param {object[]} props.pages Array of page objects
 * @param {object} props.history Router history
 * @returns Portal Container
 */
function PortalContainer(props) {
    const navBarLinks = getNavBarLinks()
    const dropdownLinks = getDropdownLinks()
    const isAuthorized = ApiManager.session.isAuthorized()
    const hasToken = ApiManager.session.hasTokens()
   
    return (
        (isAuthorized === true && hasToken === true) ?
            <Portal key='portal' pages={getPermitedPrivatePages(props.pages)} navBarLinks={navBarLinks} dropdownLinks={dropdownLinks}></Portal>
            :
            <Redirect to={{pathname: '/loading', state: {from: props.history?.location}}} />
    )
}

/**
 * Redirect Container Component
 * @param {object} props Component props
 * @param {object} props.history Router history
 * @returns Redirect Container
 */
function RedirectContainer(props) {
    /**
     * Pseudo get hasToken
     *  If hasTokens
     *      Redirect path is loading
     *  Else
     *      Redirect path is login
     *      Get location state
     */
    const hasTokens = ApiManager.session.hasTokens()
    const hasCheckedTokens = ApiManager.session.hasCheckedTokens()
    const isAuthorized = ApiManager.session.isAuthorized()
    let pathname
    let state

    if (hasCheckedTokens === true && hasTokens && isAuthorized === false) {
        pathname = '/timeout'
    }
    else if (hasTokens) {
        pathname = '/loading'
    }
    else {
        pathname = '/login'

        // If current path is loading don't populate page state
        if (props.history.location.pathname !== '/loading') {
            state = {from: props?.history?.location}
        }
    }

    return (
        <Redirect to={{pathname: pathname, state: state}}></Redirect>
    )
}

/**
 * Route Manager Component
 * @param {object} props Component Props 
 * @param {object} props.history Router history
 * @returns Route Manager Component
 */
function RouteManager({history}) {
    const [basePages, setBasePages] = useState([])
    const [portalPages, setPortalPages] = useState([])
    const [redirectPages, setRedirectPages] = useState([])

    useLayoutEffect(() => {
        /**
         * Pseudo Code
         *  Create refresh routes function
         *  Add refresh routes function to create session listener, set tokens listener, and destroy session listener
         */
        
        const refreshRoutes = () => {
            /**
             * Pseudo Code
             *  If hasTokens
             *      Generate Routes
             *  Else
             *      Generate Routes
             *  Set Page Routes
             */

            let newRedirectRoutes
            let newBaseRoutes
            let newPortalRoutes

            if (ApiManager.session.hasTokens() && ( ApiManager.session.hasCheckedTokens() === false || (ApiManager.session.hasCheckedTokens() === true && ApiManager.session.isAuthorized() === true))) {
                newPortalRoutes = buildContainerPages({containers: ['portal'], types: ['private', 'public']})
                newBaseRoutes = buildContainerPages({containers: ['base'], types: ['private', 'public']})
                newRedirectRoutes = buildContainerPages({containers: ['base', 'portal'], types: ['protected'], canRedirect: true})
            }
            else {
                newPortalRoutes = buildContainerPages({containers: ['portal'], types: ['public']})
                newBaseRoutes = buildContainerPages({containers: ['base'], types: ['protected', 'public']})
                newRedirectRoutes = buildContainerPages({containers: ['portal', 'base'], types: ['private'], canRedirect: true})
            }
            
            setBasePages(newBaseRoutes)
            setPortalPages(newPortalRoutes)
            setRedirectPages(newRedirectRoutes)
        }

        ApiManager.session.addCreateSessionListener(refreshRoutes)
        ApiManager.session.addSetTokensListener(refreshRoutes)
        ApiManager.session.addDestroySessionListener(refreshRoutes)
        
        refreshRoutes()

        return () => {
            ApiManager.session.removeCreateSessionListener(refreshRoutes)
            ApiManager.session.removeSetTokensListener(refreshRoutes)
            ApiManager.session.removeDestroySessionListener(refreshRoutes)
        }
    }, [])
    
    return (
        <Switch>
            <RedirectContainer path={getRoutesFromPages(redirectPages)} pages={redirectPages} history={history} />
            <BaseContainer path={getRoutesFromPages(basePages)} pages={basePages} history={history} />
            <PortalContainer path={getRoutesFromPages(portalPages)} pages={portalPages} history={history} />
            <BaseRoute exact path='/' />
        </Switch>
    )
}

export default withRouter(RouteManager)