/**
 * Class for handling alert REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file alerts.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class Alerts {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Gets alerts
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {boolean} searchFilters.countOnly If true get count only
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlerts(siteId = null, {page = 0, order = "DESC", search = "", sortBy = "", type = [], startTime = "", endTime = "", deviceIds = [], countOnly = false, confirmed = null, groupIds = [], timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            type,
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            ...(confirmed !== null && {confirmed: confirmed}),
            deviceId: deviceIds,
            countOnly,
            groupId: groupIds,
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }
        
        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/alerts${"?Method=Range&populate=simple&" + query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.mode Mode for specifying min or max results
     * @param {numeber} searchFilters.results Length of results to be returned
     * @returns {ResponseObject} Api response object
     */
    async getDeviceWithMaxAlerts(siteId = null, {type = [], startTime = "", endTime = "", mode = 'Max', results = 1} = {}) {
        let searchFilters = {
            mode,
            results,
            populate: 'simple',
            ...(type.length < 1 && {type: type}),
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()})
        }
        
        let query = this.session.getQuery({siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/analyticsdata/alerts/bydevice${"?Method=Range&populate=simple&" + query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets recent alerts
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {boolean} searchFilters.countOnly On true get count only
     * @returns {ResponseObject} Api response object
     */
    async getRecentAlerts(siteId = null, {page = 0, order = "DESC", search = "", sortBy = "", type = [], countOnly = false} = {}) {
        let startTime = new Date()
        startTime.setDate(startTime.getDate() - 1)

        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            type,
            startTime: startTime.getTime(),
            countOnly
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/alerts${"?Method=Range&" + query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Get a single alert's information by id
     * @param {string} id Alert uuid  
     * @returns {ResponseObject} Api response object
     */
    async getAlert({id}) {
        let config = {
            method: "get",
            url: `/api/alerts/${id}`
        }

        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
    
    /**
     * @description Saves alert settings by id
     * @param {Object} param
     * @param {string} param.id Alert id
     * @param {string} param.description Alert description
     * @returns {ResponseObject} Api response object
     */
    async saveAlert({id, description = null, confirmed = null} = {}) {
        let data = {
            Comment: description,
            Confirmed: confirmed
        }

        let config = {
            method: "put",
            url: `/api/alerts/${id}`,
            data: data, 
        }
        
        let res = await this.axios(config)

        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts count per device by site
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {number} searchFilters.results Length of results to be returned
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {string[]} searchFilters.groupIds Filter group ids to get alerts for
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByDevice(siteId = null, {page = 0, results = undefined, order = "DESC", sortBy = "count", deviceIds = [], groupIds = [], type = [], startTime = "", endTime = "", method = 'Range', confirmed = null, timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            ...(results !== undefined && {results: results}),
            deviceId: deviceIds,
            groupId: groupIds,
            type: type,
            method: method,
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/by/device?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts count per type by site
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {string[]} searchFilters.groupIds Filter group ids to get alerts for
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByType(siteId = null, {page = 0, order = "DESC", sortBy = "count", deviceIds = [], groupIds = [], type = [], startTime = "", endTime = "", method = 'Range', confirmed = null, timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            deviceId: deviceIds,
            groupId: groupIds,
            method: method,
            type,
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/by/type?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts count per type by group
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {string[]} searchFilters.groupIds Filter group ids to get alerts for
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByGroup(siteId = null, {page = 0, order = "DESC", sortBy = "count", deviceIds = [], groupIds = [], type = [], startTime = "", endTime = "", method = 'Range', confirmed = null, timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            deviceId: deviceIds,
            groupId: groupIds,
            method: method,
            type,
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, siteId, searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/by/group?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts by group details
     * @param {string} siteId Filter by site id
     * @param {object} searchFilters
     * @param {string} searchFilters.groupId Group Id to get alerts by
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByGroupDetails(siteId = null, {groupId = null, page = 0, order = "DESC", sortBy = "latest", method = "Range", type = [], startTime = "", endTime = "", confirmed = null, deviceIds = [], timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            method: method,
            ...(type.length !== 0 && {type: type}),
            ...(confirmed !== null && {confirmed: confirmed}),
            deviceId: deviceIds,
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, siteId, groupId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/alerts/by/group/details?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts by device details
     * @param {string} deviceId Filter by deviceId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByDeviceDetails(deviceId = null, { page = 0, order = "DESC", sortBy = "latest", method = "Range", type = [], startTime = "", endTime = "", confirmed = null, timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            method: method,
            ...(type.length !== 0 && {type: type}),
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, deviceId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/alerts/by/device/details?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets alerts by type details
     * @param {string} siteId Filter by siteId
     * @param {object} searchFilters
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {string[]} searchFilters.groupIds Filter group ids to get alerts for
     * @param {string} searchFilters.method Method of getting alerts, default to Range will get all ongoing and ended alerts within a time frame
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByTypeDetails(siteId = null, { page = 0, order = "DESC", sortBy = "count", method = "Range", type = [], startTime = "", endTime = "", deviceIds = [], groupIds = [], confirmed = null, timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        let searchFilters = {
            order,
            ...(sortBy === "") ? {} : {sortBy},
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            method: method,
            type: type,
            deviceId: deviceIds,
            groupId: groupIds,
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, siteId, searchFilters})
        
        let config = {
            method: 'get',
            url: `/api/alerts/by/type/details?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Gets all alerts in the system.
     * @param {object} searchFilters
     * @param {string} searchFilters.siteId Filter by siteId
     * @param {number} searchFilters.page Filter by page number
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {boolean} searchFilters.countOnly If true get count only
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAllAlerts({siteId = null, page = 0, order = "DESC", search = "", sortBy = "", type = [], startTime = "", endTime = "", deviceIds = [], countOnly = false, confirmed = null, groupIds = [], timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {

        let searchFilters = {
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            type,
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            ...(confirmed !== null && {confirmed: confirmed}),
            deviceId: deviceIds,
            ...(siteId !== null && {siteId: siteId}),
            countOnly,
            groupId: groupIds,
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({page, searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/all?${query}`,
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Returns object to populate Top Alerts page - shows highest # of alerts from a given device (provided site name)
     * @param {object} searchFilters
     * @param {string} searchFilters.siteId Filter by site id. Defaults to null.
     * @param {number} searchFilters.results Number of results to be returned
     * @param {number} searchFilters.threshold Filter by alert threshold limit (Show results above this value)
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {boolean} searchFilters.countOnly If true get count only
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByAllDevices({siteId = null, results = 20, threshold = 0, order = "DESC", search = "", sortBy = "", type = [], startTime = "", endTime = "", deviceIds = [], countOnly = false, confirmed = null, groupIds = [], timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        
        let searchFilters = {
            ...(siteId !== null && {siteId: siteId}),
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            type,
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(results !== undefined && {results: results}),
            ...(threshold !== undefined && {threshold: threshold}),
            deviceId: deviceIds,
            countOnly,
            groupId: groupIds,
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }

        let query = this.session.getQuery({searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/by/device/all${"?" + query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Returns object to populate Top Alerts page - shows highest # of alerts from a site (provided site name)
     * @param {object} searchFilters
     * @param {string} siteId Filter by site id. Defaults to null.
     * @param {number} searchFilters.results Number of results to be returned
     * @param {number} searchFilters.threshold Filter by alert threshold limit (Show results above this value)
     * @param {string} searchFilters.order Order by ASC (ascending) or DESC (descending)
     * @param {string} searchFilters.search Search by value
     * @param {string} searchFilters.sortBy Sort by field type
     * @param {string[]} searchFilters.type Filter by detection type
     * @param {string} searchFilters.startTime Filter by start time
     * @param {string} searchFilters.endTime Filter by end time
     * @param {string[]} searchFilters.deviceIds Filter device ids to get alerts for
     * @param {boolean} searchFilters.countOnly If true get count only
     * @param {boolean} searchFilters.confirmed If true filter by confirmed, if false filter by unconfirmed
     * @param {string[]} searchFilters.groupIds Group Ids to filter by
     * @param {string} searchFilters.timeZone Time zone to for processing hour of day and day of week by, defaults to null so auto timezone will be applied
     * @param {int} searchFilters.dayOfWeek Day of week to filter alerts for (0-6 for sun-sat), defaults to null so alerts will not be filtered
     * @param {int} searchFilters.hourOfDay Hour of day to filter alerts for (0-23 for 24 hour time), defaults to null so alerts will not be filtered
     * @returns {ResponseObject} Api response object
     */
    async getAlertsByAllSites({siteId = null, results = 20, threshold = 0, order = "DESC", search = "", sortBy = "", type = [], startTime = "", endTime = "", deviceIds = [], countOnly = false, confirmed = null, groupIds = [], timeZone = null, dayOfWeek = null, hourOfDay = null} = {}) {
        
        let searchFilters = {
            ...(siteId !== null && {siteId: siteId}),
            order,
            ...(search === "") ? {} : {search},
            ...(sortBy === "") ? {} : {sortBy},
            type,
            ...((startTime === "" || startTime === undefined) ? {} : {startTime: new Date(startTime).getTime()}),
            ...((endTime === "" || endTime === undefined) ? {} : {endTime: new Date(endTime).getTime()}),
            ...(confirmed !== null && {confirmed: confirmed}),
            ...(results !== undefined && {results: results}),
            ...(threshold !== undefined && {threshold: threshold}),
            deviceId: deviceIds,
            countOnly,
            groupId: groupIds,
            ...(timeZone !== null && {timeZone: timeZone}),
            ...(dayOfWeek !== null && {dayOfWeek: dayOfWeek}),
            ...(hourOfDay !== null && {hourOfDay: hourOfDay}),
        }
        
        let query = this.session.getQuery({searchFilters})

        let config = {
            method: 'get',
            url: `/api/alerts/by/site/all?${query}`,
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status, 
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Alerts