/**
 * Login Form React Component
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file LoginForm.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import React from 'react'
import { Form, Input, Button, Grid } from 'semantic-ui-react'
import { Link, withRouter } from "react-router-dom"
import { decodeSearchParams } from '../utilities/utilityFunctions'
import MicrosoftSignInButton from '../functional/MicrosoftSignInButton'
import ApiManager from '../api/ApiManager'

/**
 * @description Login Form Component
 */
class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        let searchParams = decodeSearchParams()
        let errorMessage = searchParams.invalid ?? undefined

        this.state = {
            email: '',
            password: '',
            emailError: false,
            passwordError: false,
            errors: {}, 
            formError: false,
            errorMessage: errorMessage,
            loading: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let errorMessage = (decodeSearchParams())?.invalid
        if (prevState.errorMessage !== errorMessage) {
            this.setState({errorMessage: errorMessage})
        }
    }
    
    /**
     * Handle text data changed event
     * @param {object} e Event object
     */
    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    /**
     * Handle form submit event
     * @param {object} e  Event object
     * @returns void
     */
    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({loading: true})

        // Init error handler
        let error = false
        let errors = {}

        // Email Regex
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        
        // Checks for not an empty input
        if (this.state.email === '') {
           
            // Email field is empty
            errors['email'] = {
                content: 'Please enter a valid email address',
                pointing: 'above',
            }
            
            error = true
        } 
        else {
            // Email is valid
            
            // Checks for valid email format
            if (!re.test(this.state.email)) {
                // Email is invalid
                errors['email'] = {
                    content: 'Please enter a valid email address',
                    pointing: 'above',
                }

                error = true
            }
            else {
                // Email is valid
                delete errors['email']    
            }
        }
        
        // After input validation is complete, check to see if "error" is true. 
        // If it has, set errors.
        // Return prevents the rest of handleSubmit from executing and submitting invalid data to backend

        if (error) {
            this.setState({ errors: errors, loading: false})
            return
        }

        let res = await ApiManager.router.auth.loginUser(this.state.email, this.state.password)
        
        this.setState({loading: false})
        
        let locationState = this.props.history?.location?.state

        // If login is successful
        if (res.isSuccess === true) {
            // If force two factor auth setup enabled
            if (res.data.ForceTwoFactorAuthSetup) {
                ApiManager.session.setTokens(res.data)
                
                // Redirect to loading page
                this.props.history.push({pathname: "/loading", state: {...locationState, email: this.state.email}})
            } 
            else {
                ApiManager.session.setTokens(res.data)

                // Redirect to loading page
                this.props.history.push({pathname: "/loading", state: {...locationState, email: this.state.email}})
            }
        }
        // If login is not successful
        else {
            let path = ''
            let search = ''
            let reason = ''
            
            if (res.data?.ErrorDetail === 'Temporary password expired') {
                path = '/login'
                search = `invalid=passwordExpired`
            }
            else if (res.data?.ErrorDetail === 'Session is expired') {
                path = '/login'
                search = `invalid=sessionExpired`
            }
            else if (res.data?.ErrorDetail === "Invalid credentials") {
                path = '/login'
                search = `invalid=login`
                reason = "invalid_credentials"
            }
            else if (res.data?.ErrorDetail === "User not confirmed") {
                path = '/login'
                search = `invalid=confirmed`
                reason = "account_not_confirmed"
            }
            else if (res.data?.ErrorDetail === "Limit exceeded") {
                path = '/login'
                search = `invalid=limit`
                reason = "limit_exceeded"
            }
            else if (res?.data?.ErrorDetail === "MFA required") {
                path = '/mfa'
                search = `session=${res.data.SessionToken}&email=${this.state.email}`
            }
            else if (res.data?.ErrorDetail === "New password required") {
                if (res.data?.SessionToken) {
                    path = '/update-password'
                    search = `session=${res.data.SessionToken}&email=${this.state.email}`
                }
            }
            else if (res.status === 400) {
                path = '/login'
                search = 'invalid=login'
            }
            else if (res.status === 504) {
                path = '/timeout'
            }

            // If dev mode show reason
            // eslint-disable-next-line no-undef
            if (process.env.DevMode) {
                search += `&reason=${reason}`
            }

            this.props.history.push({pathname: path, search: search, state: {...locationState}})
        }
    }

    /**
     * Handle Azure Sign-In Redirect Click event
     * @param {object} e  Event object
     * @returns void
     */
    handleAzureSignIn = async (e) => {
        e.preventDefault();
        let res = await ApiManager.router.auth.azureLoginUser()
        
        let link = res.data.Response
        window.open(link, '_self')
    }

    render() {
        let errorLabel = () => {
            if (this.state.errorMessage === undefined) {
                return (null)
            }
            else if (this.state.errorMessage === "limit") {
                return (
                    <label className='errorLabel'>
                        Maximum login attempts exceeded
                    </label>
                )
            }
            else if (this.state.errorMessage === "confirmed") {
                return (
                    <label className='errorLabel'>
                        Account hasn&apos;t been confirmed. Check your email
                    </label>
                )
            }
            else if (this.state.errorMessage === 'passwordExpired') {
                return (
                    <label className='errorLabel'>
                        Temporary password expired please contact your administrator
                    </label>
                )
            }
            else if (this.state.errorMessage === 'sessionExpired') {
                return (
                    <label className='errorLabel'>
                        Session expired, please try again
                    </label>
                )
            }
            else {
                return (
                    <label className='errorLabel'>
                        Email or password is invalid
                    </label>
                )
            }
        }
        return (
            <Grid className="loginHolder">
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            {
                                errorLabel()
                            }
                            <Form.Field
                                id='form-input-control-email'
                                className={'loginField' + (errorLabel() ? ' error' : '')}
                                control={Input}
                                type="email"
                                label='EMAIL'
                                name="email"
                                placeholder='EMAIL'
                                value={this.state.email}
                                error={this.state.errors['email']}
                                autoComplete="off"   
                                onChange={this.handleChange}
                            />
                            <Form.Field
                                id='form-input-control-password'
                                className='loginField'
                                control={Input}
                                type="password"
                                label='PASSWORD'
                                name="password"
                                placeholder='PASSWORD'
                                value={this.state.password}
                                error={this.state.errors['password']}
                                autoComplete="off"
                                onChange={this.handleChange}
                            />
                            <Form.Field
                                basic
                                inverted
                                disabled={this.state.loading}
                                id='form-button-control-public'
                                control={Button}
                                onClick={this.handleSubmit}
                            >
                                LOG IN
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <MicrosoftSignInButton 
                            handleClick={this.handleAzureSignIn}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className="loginFooter">
                            <Link to="/password">
                                FORGOT PASSWORD
                            </Link>
                            {/*
                                <span>
                                    |
                                </span>
                                <Link to='/register-site'>
                                    REGISTER SITE
                                </Link>
                            */}
                            
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>  
        )
    }
}

export default withRouter(LoginForm)