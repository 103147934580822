/**
 * Class for handling authorization REST request
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file auth.js
 * @author Kyle Watkins, Paul Scala, Matt Schreider
 */

class Auth {
    /**
     * Initialize class
     * @param {object} axios Axios instance
     * @param {object} session User session handler
     */
    constructor(axios, session) {
        this.axios = axios
        this.session = session
    }

    /**
     * @description Registers a User
     * @param {Object} params Function parameters
     * @param {string} params.email User email
     * @param {string} params.fullName User's full name
     * @param {string} params.password User's password 
     * @param {string} params.siteId User's site id
     * @param {string} params.userRole User's userRole
     * @returns {ResponseObject} Api response object
     */
    async registerUser({email = null, fullName = null, password = null, siteId = null, userRole = null} = {}) {
        let data = JSON.stringify({
            Email: email,
            FullName: fullName,
            Password: password,
            SiteId: siteId ?? "",
            ...((userRole) ? {UserRoleId: userRole} : {})
        })

        let config = {
            method: 'post',
            url: '/api/auth/register',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this. axios(config)
        
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Resends confirmation number to user
     * @param {Object} params Function parameters
     * @param {string} params.email User email
     * @returns {ResponseObject} Api response object
     */
    async resendConfirmation({email = null} = {}) {
        let data = JSON.stringify({
            Email: email,
        })

        let config = {
            method: 'post',
            url: '/api/auth/resend',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this. axios(config)
        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Confirms user's registrastion
     * @param {Object} params Function parameters
     * @param {string} params.email User email
     * @param {string} param.confirmationNumber User register confirmation number
     * @returns {ResponseObject} Api response object
     */
    async confirmUser({email, confirmationNumber}) {
        let config = {
            method: 'post',
            url: '/api/auth/confirm',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                Email: email,
                ConformationCode: confirmationNumber
            })
        }
          
        let res = await this.axios(config)

        return({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Prompt Login for a user
     * @returns {ResponseObject} Api response object
     */
    async azureLoginUser() {

        let config = {
            method: 'get',
            url: '/api/auth/azurelogin',
            headers: { 
                'Content-Type': 'application/json'
            }
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Azure Redirect once signing in correctly with Microsoft Azure Portal
     * @param {string} authCode Authorization Code received from Microsoft API
     * @returns {ResponseObject} Api response object
     */
    async azureRedirect(authCode) {

        let data = JSON.stringify({
            "code": authCode
        })

        let config = {
            method: 'post',
            url: '/api/auth/azureredirect',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }


    /**
     * @description Login a user
     * @param {string} email User email
     * @param {string} password User password
     * @returns {ResponseObject} Api response object
     */
    async loginUser(email, password) {
        let data = JSON.stringify({
            "Email": email,
            "Password": password
        })

        let config = {
            method: 'post',
            url: '/api/auth/login',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Login a user
     * @param {string} email User email
     * @param {string} password User password
     * @param {string} session User session
     * @returns {ResponseObject} Api response object
     */
    async updatePassword(email, password, session) {
        let data = JSON.stringify({
            "Email": email,
            "Password": password,
            "Session": session,
        })

        let config = {
            method: 'post',
            url: '/api/auth/updatepassword',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Verify MFA
     * @param {string} email User email
     * @param {string} session User session
     * @param {string} code User code
     * @returns {ResponseObject} Api response object
     */
    async verifyMFA(email, session, code) {
        let data = JSON.stringify({
            "Email": email,
            "Session": session,
            "Code": code
        })

        let config = {
            method: 'post',
            url: '/api/auth/mfa',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }

        let res = await this.axios(config)
        
        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Logout a user
     * @param {string} accessToken Access token
     * @param {string} refreshToken Refresh token
     * @returns {ResponseObject} Api response object
     */
    async logOutUser(accessToken, refreshToken) {
        let RefreshToken = refreshToken
        let data = JSON.stringify({RefreshToken})
        let config = {
            method: 'post',
            url: '/api/auth/logout',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            data: data
        }
          
        let res = await this.axios(config)

        this.session.initializeSession()

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Send a reset code so a user can reset their password
     * @param {string} email User email 
     * @returns {ResponseObject} Api response object
     */
    async sendResetCode(email) {
        let data = JSON.stringify({
            "Email": email
        })
          
        let config = {
            method: 'post',
            url: '/api/auth/reset',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }

        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Pass in  email, new password, and reset code to change a users password
     * @param {Object} params Function parameters
     * @param {string} params.email User email 
     * @param {string} params.password User password
     * @param {string} params.resetCode User reset code
     * @returns {ResponseObject} Api response object
     */
    async changePassword({email, password, resetCode}) {
        let data = JSON.stringify({
            "Email": email,
            "ResetCode": resetCode,
            "Password": password
        })

        let config = {
            method: 'post',
            url: '/api/auth/alter',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Register Site 
     * @param {object} props
     * @param {string} props.macAddress mac address of device 
     * @param {string} props.fullName name for user account
     * @param {string} props.email email for user account
     * @param {string} props.name site name
     * @param {string} props.userTitle user title for account
     * @param {string} props.address site address
     * @param {string} props.state site state
     * @param {string} props.zip site zip/postal code
     * @param {string} props.city site city
     * @param {string} props.country site country
     * @param {string} props.timezone site timezone
     * @returns {ResponseObject} Api response object
     */
    async registerSite({macAddress = '', fullName = '', email = '', name = '', userTitle = '', address = '', state = '', zip = '', city = '', country = '', timezone = ''} = {}) {
        let data = JSON.stringify({
            "MacAddress": macAddress,
            "FullName": fullName,
            "Email": email,
            "Name": name, 
            "UserTitle": userTitle,
            "Address": address, 
            "State": state, 
            "Zip": zip, 
            "City": city, 
            "Country": country,
            "TimeZone": timezone,
        })

        let config = {
            method: 'post',
            url: '/api/auth/registersite',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Verify Device
     * @param {object} param route body object
     * @param {string} pararm.macAddress device Mac address
     * @returns {ResponseObject} Api response object
     */
    async verifyDevice({macAddress = ''} = {}) {
        let data = JSON.stringify({
            "MacAddress": macAddress
        })

        let config = {
            method: 'post',
            url: '/api/auth/verifydevice',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }

    /**
     * @description Verify User
     * @param {object} param route body object
     * @param {string} pararm.macAddress device Mac address
     * @param {string} pararm.email user email address
     * @returns {ResponseObject} Api response object
     */
    async verifyUser({macAddress = '', email = ''} = {}) {
        let data = JSON.stringify({
            ...(macAddress !== '' && {"MacAddress": macAddress}),
            "Email": email,
        })

        let config = {
            method: 'post',
            url: '/api/auth/verifyuser',
            headers: { 
                'Content-Type': 'application/json'
            },
            data: data
        }
          
        let res = await this.axios(config)

        return ({
            status: res.status,
            statusText: res.statusText,
            isSuccess: (res.status >= 200 && res.status < 300),
            data: res.data
        })
    }
}

export default Auth