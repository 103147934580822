/**
 * Transform utilities converting to and from api data to ui friendly data for non user objects
 * @copyright 2021-2022 Soter Technologies, LLC. All rights reserved.
 * @file transformUtility.js
 * @author Kyle Watkins, Matt Schreider, Paul Scala
 */

import ApiManager from '../api/ApiManager'
import { decodeSearchParams, toMixedCase } from './utilityFunctions'

/**
 * @description Convert site info to front end friendly data
 * @param {object} site Site data return from api call
 * @param {[]object} availableUserRoles Available user roles 
 * @returns Site friendly site info
 */
function transformSiteInfo(site, availableUserRoles = null) {
    let userRoles

    if (availableUserRoles !== null) {
        userRoles = availableUserRoles.reduce((prev, curr) => ({
            ...prev,
            [curr.id]: curr.key
        }), {})
    }

    let siteInfo = {
        id: site.Id,
        name: site.Name,
        address: site.Address,
        state: site.State,
        zip: site.Zip,
        city: site.City,
        country: site.Country,
        timezone: site.TimeZone,
        type: site.OrganizationType,
        tenantId: site.TenantId,
        ...(site.IsActive !== undefined && site.IsActive !== null && {isActive: site.IsActive}),
        forceRoleTwoFactorAuth: (site.ForceRoleTwoFactorAuth ?? []).filter((role) => role.Value === true && ((!userRoles) || (role.Id in userRoles))).map((role) => role.Id) ?? [],
        ...(site.Settings && (site.Settings.Measurement && {measurement: site.Settings.Measurement})),
        ...(site.Settings && (site.Settings.Theme && {theme: site.Settings.Theme})),
        ...(site.Settings && (site.Settings.RatingsDefault && {ratingsDefault: site.Settings.RatingsDefault})),
        ...(site.Settings && (site.Settings.DashboardDefault && {dashboardDefault: site.Settings.DashboardDefault})),
    }
    return (siteInfo)
}

/**
 * @description Transform path to site friendly data
 * @param {object} path Site path
 * @returns {object} Path
 */
function transformPath(path) {
    let sitePath = {
        siteId: path.Id,
        name: path.Name,
        parentSites: path.ParentSites,
        subSites: path.SubSites 
    }
    return (sitePath)
}

/**
 * Transform Api getDevice object to be used in saveDevice api call
 * @param {object} device Api getDevice object 
 * @returns Api call ready device object
 */
function transformDevice(device) {
    // Delete interfaces from transformed object
    delete device.Interfaces
    delete device.Expiration
    delete device.RMA
    delete device.Mac
    delete device.Ip
    delete device.Model
    delete device.Version

    return {
        ...device,
    }
}

/**
 * @description Transform user list to site friendly data
 * @param {object} list List data object 
 * @returns {object} Site friendly user object
 */
function transformUserList(list) {
    let items = list.Users.map((user) => ({
        guid: user.Id,
        name: user.FullName
    }))
    
    let listData = {
        id: list.Id,
        name: list.Name,
        items: items,
        isActive: list.IsActive
    }

    return listData
}

/**
 * @description Transform Device Group to site friendly data
 * @param {object} group Group data object 
 * @returns {object} Site friendly user object
 */
function transformDeviceGroup(group) {
    let items = group.Devices.map((device) => ({
        guid: device.Id,
        name: device.Name
    }))
    
    let groupData = {
        id: group.Id,
        name: group.Name,
        items: items,
        isActive: group.IsActive
    }

    return groupData
}

/**
 * @description Transform list to API friendly data
 * @param {object} fields List fields data object 
 * @returns {object} API friendly fields object
 */
function transformDynamicList(fields) {
    fields.items = fields.items.reduce((arr, item) => { 
        arr.push(item.guid); return arr 
    }, [])
    
    return fields
}

/**
 * Transform webhook request api data to front end friendly object
 * @param {object} fields Webhook request data objectt
 * @returns Object with key names set to mixed case
 */
function transformWebhookRequest(fields) {
    /**
     * Pseudo code
     *  Convert field to mixed case
     */
    
    // Init new fields
    let newFields = toMixedCase(fields)
    
    let { auth } = newFields
    if (auth) {
        newFields.authKey = auth?.[0]?.key
        newFields.authFields = auth?.[0]?.auth ?? []
        newFields.authType = auth?.[0]?.value

        delete newFields.auth
    }
    return newFields 
}

/**
 * Transform webhook event fields to api friendly values
 * @param {object} fields Fields to transform 
 * @returns Transformed webhooks trigger object
 */
function transformWebhookEvent(fields) {
    /**
     * Pseudo Code
     *  Init devices
     *  Init sites
     *  If only one item is select
     *      If the only item selected is all
     *          Set sites to an array with the current site id
     *      Else
     *          Set devices to selected devices
     *  Else
     *      Set devices to selected devices
     *  Return transformed object
     */
    let devices = []
    let sites = []
    let site = decodeSearchParams()?.siteId ?? ApiManager.session.siteId
    
    if (fields.devices.length === 1) {
        if (fields.devices?.[0]?.guid === 'All') {
            sites = [site]
        }
        else {
            devices = fields.devices.map(d => d.guid)
        }
    }
    else {
        devices = fields.devices.map(d => d.guid)
    }

    return {
        id: fields.id,
        isActive: fields.isActive,
        name: fields.name,
        siteId: site,
        webhookConfigId: fields.webhookConfigId?.[0]?.guid,
        params: fields.params,
        devices: devices,
        sites: sites,
        groups: fields.groups.map(d => d.guid),
        events: fields.events,
        startTime: parseFloat(fields.startTime),
        endTime: parseFloat(fields.endTime),
        sunday: fields.sunday,
        monday: fields.monday,
        tuesday: fields.tuesday,
        wednesday: fields.wednesday,
        thursday: fields.thursday,
        friday: fields.friday,
        saturday: fields.saturday,
        alertTypes: (Array.isArray(fields.alertTypes) && fields.alertTypes.includes('All')) ? ['All'] : fields.alertTypes.map(d => d.guid),
    }
}

export { transformSiteInfo, transformPath, transformDevice, transformDynamicList, transformUserList, transformDeviceGroup, transformWebhookRequest, transformWebhookEvent }